<template>
  <base-view
      @click:add="criar"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <CreateEditPagamentoDialog :item="item" v-model="editDialogOpened"
                                @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <PagamentoDataTable ref="dataTable"
                           @click:edit="editar"
                           @click:remove="remover"
                           :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import PagamentoDataTable from "../../components/shared/datatables/PagamentoDataTable";
import BaseView from "../BaseView";
import {findOne, remove} from "../../api/pagamento";
import {findAll as findAllContrato} from '../../api/contrato';
import CreateEditPagamentoDialog from "../../components/shared/dialogs/CreateEditPagamentoDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {mapActions} from "vuex";
import * as moment from 'moment';

export default {
  name: "index",
  components: {CreateEditPagamentoDialog, BaseView, PagamentoDataTable},
  data() {
    return {
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Contrato',
          column: 'idContrato',
          type: 'autocomplete',
          optionsFactory: (search) => findAllContrato(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoCompleta
                }))
              })
        },
        {
          group: 'Período pagamento',
          columns: [
            {
              name: 'De',
              column: 'dtInicio',
              type: 'date',
              defaultValue: moment().startOf('month').format('YYYY-MM-DD'),
            },
            {
              name: 'Até',
              column: 'dtFim',
              type: 'date',
              defaultValue: moment().endOf('month').format('YYYY-MM-DD'),
            },
          ]
        },        
      ]
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    criar() {
      this.item = {
        idContrato: null,
        descricaoContrato: "",
        descricao: "",
        mesRef: "",
        numProcesso: "",
        valorTotal: 0,
        notas: []
      };
      this.editDialogOpened = true;
    },
    async editar(contratado) {
      const data = await this.getPagamento(contratado.id);
      this.item = data;
      this.editDialogOpened = true;
    },

    async getPagamento(id) {
      if (id){
        try {
          this.error = null;
          this.loading = true;
          const page = await findOne(id);
          let contratado = page.dados[0];
          return contratado;
        } catch (err) {
          this.error = err;
        } finally {
          this.loading = false;
        }
      }
    },
    async remover({id}) {
      try {
        await remove(id);
        this.$toast.success(`Pagamento removido com sucesso.`, {
          timeout: 3000
        });
        this.$refs.dataTable.refresh();
      } catch (err) {
        this.$toast.error(`Não foi possível remover o Pagamento.`, {
          timeout: 6000
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>